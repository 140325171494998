<template >
  <div class="container">
    Error component
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
